<template>
  <v-card v-if="invoice">
    <v-card-title>Customer Invoice</v-card-title>
    <v-card-text>
      <div v-if="invoice.stripeInvoice">Stripe Invoice: {{ invoice.stripeInvoice }}</div>
      <h2>Items</h2>
      <h4>
        {{ invoice.invoiceItems.reduce((sum, i) => i.quantity + sum, 0) }} items :
        {{ invoice.invoiceItems.filter(i => i.meal_id).reduce((sum, i) => i.quantity + sum, 0) }} meals and
        {{ invoice.invoiceItems.filter(i => i.order_type === 'shipping').reduce((sum, i) => i.quantity + sum, 0) }}
        shipping
      </h4>
      <v-row dense v-for="(item,i) of invoice.invoiceItems" v-bind:key="`item_${i}`">
        <v-col cols="1">{{ item.quantity }}</v-col>
        <v-col>{{ item.ship_date }} {{ item.description }}
          <span v-if="item.stream">({{ item.stream }})</span>
          <span v-if="item.tod">({{ item.tod }})</span>
          <span v-if="item.meal_size">({{ item.meal_size }})</span>
          {{item.order_type}}
        </v-col>
<!--        <v-col align-self="center">-->
<!--&lt;!&ndash;          <v-img max-width="100" v-if="item.photos" :src="item.photos.closeup||item.photos.plated||item.photos.packaged"/>&ndash;&gt;-->
<!--          <v-chip outlined color="warning" v-if="item.meal_id && !item.nutrients">Missing Nutrient Info</v-chip>-->
<!--        </v-col>-->
        <v-col cols="2">
          {{ getCustomerName(item.uid) }}
        </v-col>
        <v-col class="text-right" cols="1">{{ formatCurrency(item.unit_amount) }}</v-col>
        <v-col class="text-right" cols="1">{{ formatCurrency(item.total) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col></v-col>
        <v-col cols="1">Sub total</v-col>
        <v-col class="text-right" cols="1">{{ formatCurrency(invoice.total) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col></v-col>
        <v-col cols="1">GST</v-col>
        <v-col class="text-right" cols="1">{{ formatCurrency(invoice.total * 0.05) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col></v-col>
        <v-col cols="1"> Total</v-col>
        <v-col class="text-right" cols="1">{{ formatCurrency(invoice.total * 1.05) }}</v-col>
      </v-row>

      <h2>Shipments</h2>
      <v-row dense v-for="(item,i) of invoice.shipments" v-bind:key="`shipment_${i}`">
        <v-col>{{ item.shipDate }}</v-col>
        <v-col>{{ formatAddress(item.address) }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {formatAddress, formatCurrency} from "../store/utils";
import {mapGetters, mapState} from "vuex";

export default {
  name: "CustomerInvoice",
  methods: {
    formatAddress,
    formatCurrency,

  },
  props: {
    invoice: {type: Object, default: null, required: false}
  },
  computed: {
    ...mapState(['customers']),
    ...mapGetters(['getCustomerName'])
  }
}
</script>


<style scoped>

</style>