<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card v-if="saving" min-height="600">
      <v-card-text class="text-center">
        Saving the order... please wait
        <v-container>
          <v-progress-circular indeterminate/>
        </v-container>
      </v-card-text>

    </v-card>
    <v-card v-if="!saving" min-height="600">
      <v-card-title>Add Meal ( {{ stream }} )</v-card-title>
      <v-card-text v-if="mealsToAdd.length>0">
        The following meals will be added when you click Save
        <ul>
          <li v-for="(m,index) of mealsToAdd" v-bind:key="index">
            {{ m.quantity }} of {{ m.meal.name }}
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        <p class="caption">Note: if the meal on the day & time-of-day already exists the quantity you enter will updated
          (it does not add
          the quantity)</p>

        <v-tabs centered v-model="tabs" class="mb-4">
          <v-tab>Regular Stream Meal</v-tab>
          <v-tab>Swap/Substitute Meal</v-tab>
          <v-tab>Snacks & Extras</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <h3>Regular Stream Meal</h3>
            <v-row>
              <v-col cols="1">
                <v-text-field
                  label="Qty"
                  type="number"
                  min="0"
                  v-model="quantity"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  :loading="loading"
                  label="Meal"
                  :items="meals"
                  item-value="id"
                  item-text="name"
                  return-object
                  v-model="meal"
                >
                  <template v-slot:selection="data">
                    {{ dateFormatted(data.item.date) }} - {{ data.item.name }} ( {{ (data.item.tod) }} )
                  </template>
                  <template v-slot:item="data">
                    {{ dateFormatted(data.item.date) }} - {{ data.item.name }} ( {{ (data.item.tod) }} )
                    <v-spacer/>
                    <span v-if="data.item.order_type==='chefs_choice'">
            Chefs Choice ({{ formatCurrency(data.item.price) }})
          </span>
                    <span v-else>
             {{ (data.item.stream) }}
          </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <h3>Substitute Meal</h3>
            <v-row>
              <v-col cols="1">
                <v-text-field
                  label="Qty"
                  type="number"
                  min="0"
                  v-model="quantity"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  :loading="loading"
                  label="Day"
                  v-model="subDay"
                  :items="dates.filter(notOnSunday)"
                >
                  <template v-slot:item="data">
                    {{ dateFormatted(data.item) }}
                  </template>
                  <template v-slot:selection="data">
                    {{ dateFormatted(data.item) }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Time of Day"
                  v-model="subTod"
                  :items="`breakfast lunch dinner`.split(' ')"
                />
              </v-col>

            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :loading="loading"
                  label="Substitute Meal"
                  :items="allowedSubMeals"
                  item-value="id"
                  item-text="name"
                  :disabled="!(subDay&&subTod)"
                  return-object
                  v-model="meal"
                >
                  <template v-slot:selection="data">
                    {{ (data.item.date) }} - {{ data.item.name }} ( {{ (data.item.tod) }} )
                  </template>
                  <template v-slot:item="data">
                    {{ (data.item.date) }} - {{ data.item.name }} ({{ data.item.stream}} {{ (data.item.tod) }})
                    <v-spacer/>
                    <span v-if="data.item.price">({{ formatCurrency(data.item.price) }})</span>
                    <span v-else>missing price</span>
                  </template>
                </v-autocomplete>
                <pre v-if="allowedSubMeals[0]">{{ allowedSubMeals[0].order_type }}</pre>

              </v-col>
            </v-row>

          </v-tab-item>
          <v-tab-item>

            <h3>Snacks and Extras</h3>
            <v-row>
              <v-col cols="1">
                <v-text-field
                  label="Qty"
                  type="number"
                  min="0"
                  v-model="quantity"
                />
              </v-col>
              <v-col>
                <v-autocomplete
                  :loading="loading"
                  label="Day"
                  v-model="ccDay"
                  :items="dates.filter(notOnSunday)"
                >
                  <template v-slot:item="data">
                    {{ dateFormatted(data.item) }}
                  </template>
                  <template v-slot:selection="data">
                    {{ dateFormatted(data.item) }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Snacks and Extras"
                  :items="extras"
                  item-value="id"
                  item-text="name"
                  return-object
                  v-model="meal"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }} ( {{ (data.item.tod) }} )
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }} ( {{ (data.item.tod) }} )
                    <v-spacer/>
                    <span v-if="data.item.order_type==='chefs_choice'">
            Chefs Choice ({{ formatCurrency(data.item.price) }})
          </span>
                    <span v-else>
             {{ getDietName(data.item.diet) }}
          </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <!--        <pre>{{ meal }}</pre>-->
      </v-card-text>

      <v-card-actions>
        <v-btn color="warning" @click="$emit('close')">Cancel</v-btn>
        <v-spacer/>
        <v-btn :disabled="saveDisabled" color="default" @click="addMore">Add More</v-btn>
        <v-spacer/>
        <v-btn :loading="saving" :disabled="saveDisabled  && mealsToAdd.length===0" color="primary" @click="save">Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {dateFormatted, deepCopy, formatCurrency, getDateString} from "@/store/utils";

export default {
  name: "AddMealForm",
  props: {
    uid: {},
    stream: {},
    saving: {},
    dateFrom: {type: String, default: null, required: false},
    dateTo: {type: String, default: null, required: false}
  },
  data() {
    return {
      extras: [],
      quantity: 1,
      meal: null,
      allStreamMeals: null,
      mealsToAdd: [],
      subMeals: null,
      subDay: null,
      subTod: null,
      tabs: null,
      ccDay: null,
      loading: null
    }
  },
  watch: {
    dateFrom: 'fetchMeals',
    dateTo: 'fetchMeals'
  },
  async mounted() {
    console.log('fetching meals on dates', this.dates);
    this.allStreamMeals = [];
    return this.fetchMeals();
  },
  computed: {
    ...mapGetters(['getTimeOfDay', 'getStreamDiet', 'getDietName']),
    mealData() {
      const meal = deepCopy(this.meal);
      if (this.ccDay) {
        meal.date = this.ccDay;
      }
      if (this.subDay) {
        meal.date = this.subDay;
        meal.is_substitution = true;
      }
      if (this.subTod) {
        meal.tod = this.subTod;
      }
      console.log('save meal data', meal);
      return meal;
    },
    dates() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    saveDisabled() {
      return !(this.meal && (this.meal.date || this.ccDay));
    },
    meals() {
      return (this.allStreamMeals || []).slice();
    },
    allowedSubMeals() {
      // first find a meal on the same day of the week
      const mealOnSameDay = (this.subMeals || []).find(m => m.date === this.subDay);
      if (!mealOnSameDay) {
        return []
      }
      // now filter meals based on `mealOnSameDay`'s delivery date
      return this.subMeals.filter(m => m.deliveryDate === mealOnSameDay.deliveryDate);
    }
  },
  methods: {
    dateFormatted,
    ...mapActions(['fetchMenu', 'fetchPersonalMenu', 'fetchMeals', 'fetchStreams', 'fetchDiets', 'fetchExtras']),
    formatCurrency,
    async fetchMeals() {
      this.loading = true;

      const menus = await this.fetchMenu(this.dateFrom);

      function flattenMenu(menu) {
        return menu.flatMap(menuDate => menuDate.meals || []);
      }

      const subMeals = [];
      let streamMenu;
      if (this.stream === 'personal') {
        streamMenu = await this.fetchPersonalMenu({date: this.dateFrom, uid: this.uid})
      } else {
        streamMenu = menus[this.stream];
      }

      if (!streamMenu) {
        alert('could not find stream menu: ' + this.stream);
        // console.warn('could not find specific menu for stream, adding all meals (presume flex) ' + this.stream);
        // this.allStreamMeals = Object.values(menus).flatMap(streamMenu => streamMenu.menu);
      } else {
        this.allStreamMeals = flattenMenu(streamMenu.menu);
        Object.keys(menus).forEach(stream => {
          if (stream === this.stream) {
            // don't sub stream meals
          } else if (menus[stream].derived || stream === 'flex') {
            //don't add derived or flex
          } else {
            subMeals.push(...flattenMenu(menus[stream].menu));
          }
        });
      }
      // console.log('sub meals loaded, checking for flex', subMeals.filter(m => m.order_type === 'flex'));

      this.extras = menus.full.menu.find(dayMenu => dayMenu.date === 'any').products;

      function fmt(a) {
        return `${a.date}-${a.tod}-${a.name}`;
      }

      subMeals.sort((a, b) => fmt(a).localeCompare(fmt(b)));
      this.allStreamMeals.sort((a, b) => fmt(a).localeCompare(fmt(b)));
      this.subMeals = subMeals;

      this.loading = false;
    },
    save() {
      const {quantity, mealsToAdd} = this;
      const meal = this.mealData;
      if (meal) {
        console.log(`saving ${meal.name} ${meal.tod} ${meal.date}`, {quantity, meal, mealsToAdd});
        this.mealsToAdd.push({quantity, meal});
        this.meal = null;
        this.subTod = null;
        this.subDay = null;
        this.ccDay = null;
      }
      this.$emit('save', this.mealsToAdd)
      this.mealsToAdd = [];
    }
    ,
    addMore() {
      const {quantity} = this;
      const meal = this.mealData;
      if (meal) {
        this.mealsToAdd.push(deepCopy({quantity, meal}));
      }
      this.meal = null;
      this.subTod = null;
      this.subDay = null;
      this.ccDay = null;
    }
    ,
    notOnSunday(d) {
      return moment(d).format('ddd') !== 'Sun'
    }
  },
}
</script>

<style scoped>

</style>