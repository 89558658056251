<template>
  <div>
    <v-dialog v-model="showInvoiceDialog">
      <CustomerInvoice :invoice="invoice"/>
    </v-dialog>
    <v-dialog
        v-model="showAddMealDialog"
        ref="dialogMissing"
        width="800px"
    >
      <AddMealForm v-if="stream"
                   :stream="stream"
                   :date-from="dateFrom"
                   :date-to="dateTo"
                   v-on:close="showAddMealDialog=false"
                   v-on:save="saveAddMeals"
                   :saving="saving"
                   :uid="customer.uid"
      />
    </v-dialog>
    <v-container fluid>
      <v-toolbar flat class="d-print-none pb-10">
        <v-text-field :value="datesFormatted"
                      label="Select dates"
                      single-line
                      hide-details
                      readonly
                      @click="showDatePicker=true"
                      append-icon="mdi-calendar"
        />
        <v-spacer/>
        <v-autocomplete
            clearable
            single-line
            hide-details
            label="Customer"
            v-model="customerId"
            :items="sortedCustomers"
            :item-text="(c) => `${[c.first_name,c.last_name].join(' ')} (${c.email})`"
            item-value="uid"
            :disabled="loadingCustomers"
            :loading="loadingCustomers"
        />

        <v-dialog
            v-model="showDatePicker"
            ref="dialog"
            width="290px"
            persistent
        >
          <v-date-picker
              v-model="datePickerDate"
              no-title
              @close="closeDatePicker"
          ></v-date-picker>
          <v-btn @click="closeDatePicker">Close</v-btn>
        </v-dialog>
      </v-toolbar>
      <v-alert outlined v-if="isPastOrderDeadline" type="warning">
        <span v-if="isLocked">You cannot edit this order as it is in the past.</span>
        <span v-if="!isLocked">You are editing an order that may be in production or is in the past.</span>
      </v-alert>
      <v-toolbar v-if="isLocked" flat>
        <v-spacer/>
        <v-btn @click="isLocked=false" outlined color="error">I KNOW WHAT I'M DOING, LET ME EDIT IT
          ANYWAY
        </v-btn>
      </v-toolbar>
      <v-dialog
          v-model="showMissing"
          ref="dialogMissing"
          width="500px"
      >
        <v-card>
          <v-card-title>Warning</v-card-title>
          <v-card-text>
            The following meal ids were not found in the database. Any ingredients required for these meals is not show.
            <v-list>
              <v-list-item v-for="mealId of missing_meals" v-bind:key="mealId">
                {{ missing_count[mealId] }} orders for {{ mealId }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-card class="ma-4" v-if="customer">
        <v-card-title>
          {{ customer.name }} (
          <router-link :to="{name:'Customer', params: { id : customerId}}">{{ customer.email }}</router-link>
          )
          <template v-if="customer.mainAccount">
            <v-spacer/>
            Main Account:&nbsp;<router-link :to="linkToMain"> {{ getCustomerName(customer.mainAccount) }}</router-link>
          </template>
          <v-spacer/>
          {{ customer.plan }} ( {{ stream }} )
        </v-card-title>
        <v-card-text>
          {{ customer.uid }}
          <v-alert outlined type="warning" v-if="activePause">
            Pause Active: {{ customer.pause.map(dateFormatted).join(' to ') }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="isLocked" color="primary"
                 @click="showAddMeal({uid: customer.uid, stream: customer.stream})">Add Meal
          </v-btn>
          <v-spacer/>
<!--          <v-btn :disabled="isLocked" @click="updateInvoice">Update Stripe Invoice</v-btn>-->
<!--          <v-spacer/>-->
          <v-btn :disabled="isLocked" :loading="saving" color="warning" @click="clearMealsConfirm">Clear All Meals
          </v-btn>
          <v-spacer/>
          <v-btn :disabled="!invoice" @click="showInvoiceDialog=true">
            Total (w/ GST) {{ formatCurrency(this.total * 1.05) }}
          </v-btn>

        </v-card-actions>

        <v-data-table
            :items="orders"
            :headers="headersCustomerOrder"
            :disable-pagination=true
            :hide-default-footer=true
            :fixed-header=false
            :loading="loading"
        >
          <template v-slot:item.date="{ item }">
            {{ dateFormatted(item.date) }}
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-text-field
                class="quantity-input"
                v-model="item.quantityEdit"
                type="number"
                min="0"
                @change="$nextTick(()=>updateQuantity(item))"
                :disabled="isLocked || readOnly || item.no_edit"
            />
          </template>
        </v-data-table>

      </v-card>
    </v-container>


    <v-footer fixed class="d-print-none pl-10 pr-8" v-if="!loading && datesFormatted">
      {{ datesFormatted }}
      <v-spacer/>
      {{ totalCount }} meals
      {{ orders.length }} orders
      <v-spacer/>
      <v-chip
          v-if="missing_meals.size"
          color="warning" class="mr-1"
          @click="showMissing=true"
      >{{ missing_meals.size }} meals not found
      </v-chip>
      <!--      <v-chip color="secondary"-->
      <!--              close-->
      <!--              @click:close="selected=[]"-->
      <!--              v-if="totalRawSelected">Raw Selected {{ totalRawSelected }}-->
      <!--      </v-chip>-->
      <!--      <v-chip color="primary" v-if="!totalRawSelected">Raw Total {{ totalRawAll }}</v-chip>-->
    </v-footer>
    <!-- blank gap at the bottom to avoid fixed footer hiding data-table -->
    <div class="ma-8"></div>

  </div>


</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import moment from 'moment';
import {dateFormatted, ensureInt, formatCurrency, getDateString} from '@/store/utils'
import api from '@/api';
import urlState from "@/router/urlState";
import AddMealForm from "@/components/AddMealForm";
import CustomerInvoice from "@/components/CustomerInvoice.vue";


export default {
  components: {CustomerInvoice, AddMealForm},
  mixins: [urlState],
  data() {
    return {
      isLocked: false,
      datePickerDate: null,
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      respondToRouteChanges: true,
      streamsSelected: [],
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      headers: [
        // {
        //   text: 'Email',
        //   align: 'start',
        //   value: 'email',
        //   width: '*'
        // },
        {text: 'Quantity', value: 'quantity'},
        // {text: 'Id', value: 'id' },
        {text: 'Meal ID', value: 'meal_id'},
        {text: 'Bin', value: 'bin'},
        {text: 'Meal', value: 'mealName'},
        {text: 'Customer Name', value: 'name',},
        {text: 'Email', value: 'email',},
        {text: 'Stream', value: 'stream'},
        {text: 'Time of day', value: 'tod'},
        {text: 'Date', value: 'date'}

      ],
      headersCustomerOrder: [
        // {
        //   text: 'Email',
        //   align: 'start',
        //   value: 'email',
        //   width: '*'
        // },
        {text: 'Meal ID', value: 'meal_id'},
        {text: 'Quantity', value: 'quantity'},
        // {text: 'Id', value: 'id' },
        {text: 'Meal', value: 'mealName'},
        // {text: 'Customer Name', value: 'name',},
        // {text: 'Email', value: 'email',},
        // {text: 'Stream', value: 'stream'},
        {text: 'Time of day', value: 'tod'},
        {text: 'Date', value: 'date'},
        {text: 'Stream', value: 'stream'},
        // {text: 'Type', value: 'order_type'},
        {text: 'Bin', value: 'bin'},
        {text: 'Once', value: 'only_once'},
        {text: 'Sub', value: 'is_substitution'},
        {text: 'Sub Meal ID', value: 'meal_id'},
        // {text: 'Order Type', value: 'order_type'},
        // {text: 'Order Stream', value: 'order_stream'},

      ],
      showMissing: false,
      setRouteTimeout: null,
      filteredIngredients: [],
      maxEmailPerLink: 450,
      tableMode: 0,
      showAddMealDialog: null,
      addMealValues: {},
      customerId: null,
      mealsLoaded: false,
      isPastOrderDeadline: null,
      invoice: null,
      showInvoiceDialog: null,
      ordersFromApi: [],
      saving: null
    }
  },
  async created() {
    this.syncToUrl(
        {
          param: 'customerId', urlParam: 'customer',
          initFromRoute: true
        });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates',
      initFromRoute: true
    });
    if (this.dates && this.dates.length > 0) {
      this.setDateRange(this.dates[0]);
    }
    await Promise.all([
      this.fetchPlans(),
      // this.fetchDiets(),
      this.fetchCustomers(),
      // this.customerId ? this.fetchData() : false
    ])
        .catch((e) => {
          console.error('something went wrong', e);
        })
        .finally(() => this.loading = false);
  },
  watch: {
    loading(v) {
      console.log('loading is ' + v);
    },
    customerId(id, oldId) {
      if (id === oldId) {
        console.log('no need to update customer id', {id, oldId});
        return;
      }
      console.log('setting customer', id);
      const isInPast = moment(this.dateFrom).isBefore(moment());
      console.log('they are set', [this.dateFrom, this.dateTo, this.customerId, isInPast]);
      this.isPastOrderDeadline = this.isLocked = isInPast;
      return this.fetchData();
    }
  },
  computed: {
    ...mapGetters(['getCustomerName']),
    customer() {
      if (this.loadingCustomers) {
        return false;
      }
      const customer = this.customerId && this.customers && this.customers[this.customerId];

      return customer
          ? {
            name: [customer.first_name, customer.last_name].filter(s => !!s).map(s => s.trim()).join(' '),
            ...customer
          }
          : false;
    },

    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    ...mapState(['customers', 'plans']),
    ...mapGetters([
      'meals',
      'diets',
      'getMeal',
      'getMealIngredients',
      'getDietName',
      'getDietStream',
      'getTimeOfDay',
      'getSummaryForDiet',
      'getStreamDiet',
      'getOrdersOnDate',
      'getCustomer'
    ]),
    ordersUnfiltered() {
      const orders = this.ordersFromApi.slice();
      // console.log('concat orders from dates', {dates: this.dates});
      // this.getDatesInRange()
      //     .forEach(date =>
      //         orders.push(...(
      //             this.getOrdersOnDate({date}) || []
      //         )));
      // const orders = this.customerOrders || [];
      console.log('orders unfiltered this.mealsLoaded=' + this.mealsLoaded, orders);
      orders.forEach(o => o.name = [o.first_name, o.last_name].filter(s => !!s).map(s => s.trim()).join(' '));
      orders.forEach(o => o.mealName = (this.getMeal(o.meal_id) && this.getMeal([o.meal_id]).name)
          || 'could not find meal');
      orders.forEach(o => o.customer = this.getCustomer(o.uid));
      orders.forEach(o => o.email = o.customer ? o.customer.email : '');
      orders.forEach(o => o.quantityEdit = o.quantity);
      return orders;
    },
    orders() {
      if (this.loading || !this.mealsLoaded) {
        return [];
      }
      let orders = this.ordersUnfiltered;
      orders = orders.filter(o => o.uid === this.customerId)
      console.log('orders', orders);
      const streamsSelected = this.streamsSelected;
      if (streamsSelected.length > 0) {
        return orders.filter(o => streamsSelected.includes(o.stream));
      } else
        return orders;
    },

    totalCount() {
      let count = 0;
      this.orders.forEach(o => count += o.quantity);
      return count;
    },
    summary() {
      return this.$store.getters.summary(this.date);
    },
    streams() {
      const streams = new Set();
      this.ordersUnfiltered.forEach(o => streams.add(o.stream));
      // console.log('streams', streams);
      return [...streams.values()].sort();
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    sortedCustomers() {
      const sortedCustomers = Object.values(this.customers || {})
          .map(c => ({
            ...c,
            name: [c.isDeleted ? 'ZZZZ' : '', c.first_name, c.last_name].filter(s => !!s).map(s => s.trim()).join(' ')
          }));
      return sortedCustomers.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    loadingCustomers() {
      return !this.customers;
    },
    customerOrders() {
      return this.o
    },
    plan() {
      return this.plans[this.customer.plan];
    },
    diet() {
      const stream = this.stream;
      if (stream === 'flex') {
        return 'all';
      }
      return this.getStreamDiet(stream)
    },
    stream() {
      // console.log('plan', this.plan);
      return this.plan && this.plan.stream;
    },
    isFlex() {
      return this.stream === 'flex';
    },
    total() {
      return this.invoice && this.invoice.total;
    },
    linkToMain() {
      const currentQuery = this.$route.query;
      return {
        query: {
          ...currentQuery,
          customer: this.customer.mainAccount
        }
      }
    },
    activePause() {
      // console.log('checking pause', this.customer && this.customer.pause);
      if (this.customer && this.customer.pause) {
        const fromOverlap = moment(this.dateFrom).isBetween(this.customer.pause[0], this.customer.pause[1], 'day', '[]');
        const toOverlap = moment(this.dateTo).isBetween(this.customer.pause[0], this.customer.pause[1], 'day', '[]');
        return fromOverlap || toOverlap;
      }
      return false;
    }
  },
  methods: {
    formatCurrency,

    ...mapActions([
      'fetchFedInvoice',
      'fetchDiets',
      'fetchMeals',
      'fetchMeal',
      'fetchMealIngredients',
      'fetchOrdersByDate',
      'fetchCustomers',
      'fetchPlans',
      'fetchDiets',
    ]),
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    async fetchData() {
      if (!(this.dateFrom && this.dateTo && this.customerId)) {
        console.log('date and customer must be provided');
        return;
      }
      const key = [this.dateFrom, this.dateTo, this.customerId].join('-');

      if (this.currentlyFetching === key) {
        console.log('already fetching', key)
        return;
      } else {
        console.log('now fetching', key);
      }
      this.currentlyFetching = key;

      this.invoice = null;
      this.error = this.post = null
      this.loading = true;

      this.mealsLoaded = false;
      const uid = this.customerId;
      const fetchPromises = [];

      fetchPromises.push(this.fetchFedInvoice({
        date: this.dateFrom,
        uid,
        refetch: true
      }).then(r => this.invoice = r || null))

      const url = `v2/order/${this.getDatesInRange().join(',')}/${uid}`;
      const orders = await api.get(url).then(r => r.data);
      console.log('api orders', orders);
      const meals = {};
      orders.forEach(order => {
        const mealCount = meals[order.meal_id] || {};
        mealCount[order.meal_size] = mealCount[order.meal_size] || 0;
        mealCount[order.meal_size]++;
        meals[order.meal_id] = mealCount;
        order.tod = order.order_tod || order.tod;
        order.date = order.order_date || order.date;
      });
      this.ordersFromApi = orders.sort((a, b) => `${a.date}${a.mealName}`.localeCompare(`${b.date}${b.mealName}`));
      fetchPromises.push(
          Promise.all(Object
              .keys(meals)
              .map(id => this.fetchMeal(id)
                  .catch(e => {
                    console.warn('failed to get ingredients for ' + id, e);
                  })))
              .then(() => this.mealsLoaded = true)
      );
      return Promise.all(fetchPromises).finally(() => {
        this.loading = false;
        this.currentlyFetching = false;
      });
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    async closeDatePicker() {
      this.showDatePicker = false;
      const date = this.datePickerDate;
      if (date) {
        this.setDateRange(date);
        this.$nextTick(() => {
          this.fetchData();
        })
      }
    },
    dateFormatted
    // setFilteredItems(filteredIngredients) {
    //   console.log('filteredIngredients', filteredIngredients);
    //   this.filteredIngredients = filteredIngredients;
    // }
    ,
    getEmails(orders) {
      const emails = new Set()
      orders.forEach(o => o.email && emails.add(o.email));
      // orders.forEach(o => o.email && emails.add(`${o.first_name} ${o.last_name} <${o.email}>`));
      return [...emails];
    },
    getEmailChunks(orders, chunkSize) {
      const emails = this.getEmails(orders);
      const chunks = [];
      let i = 0;
      while (i < emails.length) {
        const upper = Math.min(chunkSize + i, emails.length);
        chunks.push({chunk: `${i + 1} to ${upper}`, emails: emails.slice(i, upper)});
        i += chunkSize;
      }
      return chunks;
    },
    updateQuantity(item) {
      console.log('updating ', item);
      const oldValue = item.quantity;
      item.quantity = parseInt(item.quantityEdit || '0');
      const {mealName, stream, meal_id, name, email, date, ship_date} = item;
      const payload = {
        mealName, stream, meal_id, name, email, date, ship_date,
        uid: this.customer.uid,
        quantity: item.quantity,
        lineItemId: item.id
      };
      return api.put(`v2/order/update-line-item`, payload)
          .then(result => this.invoice = result.data)
          .catch(e => {
            item.quantity = oldValue;
            item.quantityEdit = oldValue;
            console.error('update failed ', {item, error: e.message});
          });
    },
    showAddMeal(values) {
      this.showAddMealDialog = true;
      this.addMealValues = values;
    },
    async clearMealsConfirm() {
      if (confirm('are you sure you want to remove all meals from this customer?')) {
        this.saving = true;
        for (const o of this.orders) {

          if (o.no_edit) {
            console.info('cannot clear no_edits', o);
          } else {
            o.quantityEdit = 0;
            await this.updateQuantity(o);
          }
        }
        this.saving = false;
      }
    },
    async saveAddMeals(mealsToAdd) {
      this.saving = true;
      await Promise.all(mealsToAdd.map(async values => {
        let {quantity, meal} = values;
        quantity = ensureInt(quantity);
        if (isNaN(quantity)) {
          alert('quantity is not a valid integer');
          throw new Error('quantity is not a valid integer');
        }
        let {date, tod} = meal;
        // tod = this.getTimeOfDay(tod);
        if (meal.stream === 'chefs_choice') {
          meal.order_type = 'chefs_choice';
        }
        console.log('save add meal', values);


        const payload = {
          uid: this.customer.uid,
          quantity,
          mealId: meal.id,
          date,
          tod,
          order_type: meal.order_type || (this.isFlex ? 'flex' : 'full'),
          mealName: meal.name,
          stream: meal.stream,
          email: this.customer.email
        };
        if (!this.isFlex && meal.is_substitution) {
          payload.is_substitution = true;
        }
        console.log('save payload', payload);
        await api.post(`v2/order/add-line-item`, payload)
            .then(result => {
              console.log('updated customer invoice amounts', result);
              this.invoice = result.data;
            })
            .catch(e => {
              console.error('add failed ', {values, payload, error: e.message});
            });
      }));


      setTimeout(() => {
        this.showAddMealDialog = false;
        console.log('fetching')
        this.saving = false;
        this.fetchData();
      }, 5000);

    },
    updateInvoice() {
      const now = moment();
      const startInvoiceTime = now.clone().startOf('hour').hour(1);
      const closedInvoiceTime = now.clone().startOf('hour').hour(11);

      if (now.isAfter(this.dateFrom)) {
        if (!confirm('YOU ARE UPDATING AN OLD INVOICE.  THIS IS PROBABLY WRONG!    DO YOU WANT TO CONTINUE?\n\n(sorry for shouting, hit cancel please)')) {
          return;
        }
      }


      if (!(now.isoWeekday() === 5
          && now.isBefore(closedInvoiceTime)
          && now.isAfter(startInvoiceTime))) {
        if (!confirm('YOU ARE EDITING OUTSIDE THE INVOICE TIME.  THIS IS PROBABLY WRONG!  DO YOU WANT TO CONTINUE?\n\n(sorry for shouting, hit cancel please)')) {
          return;
        }
      }


      if (confirm('If there is currently an invoice for this customer, this will update it.  If no invoice exists, there is no need to push this')) {
        const payload = {
          uid: this.customerId,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        }

        this.loading = true;
        api.post('/v2/stripe/update/', payload)
            .then(r => {
              console.log('result', r);
              alert('customer updated');
            })
            .catch(e => {
              console.warn('failed', e);
              alert('failed: ' + e.message);
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    setDateRange(date) {
      this.dates = [
        moment(date).startOf('week').format(moment.HTML5_FMT.DATE),
        moment(date).endOf('week').format(moment.HTML5_FMT.DATE)
      ];
    }
  }
}
</script>

<style>
.quantity-input {
  width: 50px;
}

.quantity-input .v-text-field__slot input {
  text-align: right;
}
</style>
